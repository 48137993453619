import React from "react";
import "../../styles/Info.css"

const Info = (props) => {

    const values = [];

    Object.keys(props.values).forEach((key) => {
        if (key !== "discount") values.push(props.values[key]);
        
    })

    return (
        <div className="info">
            <h2>Информация о выборе</h2>
            {values.map((el, i) => {
                
                if (i === 2 && el.length > 0) return <div className="info-card info-card-array" key={i}>
                    <div className="info-defects-title">Дефекты</div>
                    <div className="info-defects-container">{el.map((element, index) => {return <div key={index}>{element.title}</div>})}</div>   
                </div>
                // eslint-disable-next-line
                if (el != false) return <div key={i} className="info-card"><div> {el.title}</div></div>
                return ''
            })}
        </div>
    )
}

export default Info;