import React, { useState } from "react";
import PreferedTimeCard from "./PreferedTimeCard";
import "../../styles/PreferedTime.css"


const PreferedTime = (props) => {
    const [options, setOptions] = useState([{'id': 1, 'title': 'Сегодня'}, {'id': 2, 'title': 'Завтра'}, {'id': 3, 'title': 'В течении недели'}, {'id': 4, 'title': 'В течении месяца'}]);

    const selectPreferedTime = (id, title) => {
        props.setSelected({
            "state": "preferedTime",
            "value": {
                "id": id,
                "title": title
            }
        })
    }

    return (
        <div className="prefered-time-container">
            {
                options.map((el, i) => {
                    return <PreferedTimeCard el={el} key={i} selected={props.selected} setSelected={props.setSelected} selectPreferedTime={selectPreferedTime} />
                })
            }
        </div>
    )

}

export default PreferedTime