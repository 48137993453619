import React from "react";
import { useForm } from "react-hook-form";
import "../../styles/NamePhoneForm.css"

const NamePhoneForm = (props) => {
    const {
        register,
        setError,
        formState: { errors },
        handleSubmit
    } = useForm();

    const onSubmit = (data) => {
        let firstName = data.firstName
        let phoneNumber = data.phoneNumber;
        if (!phoneNumberValidator(phoneNumber)) {
            setError('phoneNumber', {message: "Пожалуйста, используйте ваш реальный номер телефона"});
            return;
        }
        let postData = {
            firstName: firstName,
            phoneNumber: phoneNumber,
            model: props.values.model.id,
            defects: props.values.selectedDefects.map((el) => {return el.id}),
            preferedTime: props.values.preferedTime.title
        }
        props.addClient(postData);
        props.setCustomerInfo({
            firstName: firstName,
            phoneNumber: phoneNumber,
        })
        props.setStep(props.step + 1);
    }

    const phoneNumberValidator = (data) => {
        let phoneNumber = data.replace(/\D/g, '').replace(/^7/, '8');
        // console.log(phoneNumber[0] === '8' && phoneNumber.length === 11);
        
        if (phoneNumber[0] === '8' && phoneNumber.length === 11 ) return true;
        else return false;
    }
    
    return (
        <div className="name-phone-form-container">
            <h1 className="discount-title">Введите имя и номер, чтобы получить скидку 10%!</h1>
            <form onSubmit={handleSubmit(onSubmit)} className="name-phone-form">
                <div className="input-container">
                    <input 
                    {...register('firstName', {
                        required: "Поле обязательно к заполнению",
                    })}
                    id="first-name-input"
                    autoComplete="off"
                    placeholder="Введите ваше имя">
                    </input>
                    <div className="errors">{errors?.firstName && <p className="error">{errors?.firstName?.message || "Error!"}</p>}</div>
                    <input
                    {...register('phoneNumber', {
                        required: "Поле обязательно к заполнению"
                    })}
                    id="phone-number-input"
                    autoComplete="off"
                    placeholder="Введите ваш номер телефона">
                    </input>
                    <div className="errors">{errors?.phoneNumber && <p className="error">{errors?.phoneNumber?.message || "Error!"}</p>}</div>
                </div>
                <div className="submit-form-button-container">
                    <button type="submit" className="submit-form-button">Следующий шаг</button>
                </div>
            </form>
        </div>
    )
}

export default NamePhoneForm;