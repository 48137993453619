import React, { useEffect } from "react";
import "../../styles/PriceOutput.css"

const PriceOutput = (props) => {
    
    useEffect(() => {
        props.getPrice();
// eslint-disable-next-line
    }, [])

    return (
        <div className="price-output-container">
            {(props.price === "not found")
            ?   <div className="price-not-found">
                    <div>Не удалось определить цену. Мы свяжемся с вами позже.</div>
                </div>
            :   <div className="price-output">
                    <div className="repair-type-container">
                        <div className="repair-type-title">
                            Вид Ремонта
                        </div>
                        <div className="repair-type">
                            {props.repairType}
                        </div>
                    </div>
                    <div className="price-container">
                        <div className="price-title">
                            Цена Ремонта
                        </div>
                        <div className="price">
                            {props.price} руб.
                        </div>
                    </div>
                </div>
            }
            <div className="contacts-container">
                <div className="contacts-title">Контакты</div>
                <div className="contacts">
                    <div className="contact">+7(123)456-78-90</div>
                </div>
            </div>
        </div>

    )
}

export default PriceOutput;