import React from "react";
import ModelCard from "./ModelCard";
import "../../styles/Models.css";

const Models = (props) => {

    const selectModel = (value) => {
        props.setSelected({
            state: 'model',
            value: value
        });
    }

    return (
        <div className="models-container">
            {
                props.data.brandsModels.find(el => el.id === props.values.brand.id)['models'].map((el, i) => {
                    return <ModelCard selected={props.selected} selectModel={selectModel} title={el.title} id={el.id} key={i} />
                })
            }
        </div>
    )
}

export default Models;