import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from "./routes/Home";
import Quiz from "./routes/Quiz";
import PageNotFound from './routes/PageNotFound';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/quiz" element={<Quiz />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </BrowserRouter>
);