import React from "react";

class Home extends React.Component {
    render () {
        return (
            <div>
                <h1>Home</h1>
            </div>
        )
    }
}

export default Home;