import React from "react";
import BrandCard from "./BrandCard";
import "../../styles/Brands.css";

const Brands = (props) => {

    const selectBrand = (value) => {
        props.setSelected({
            state: 'brand',
            value: value
        });
    }

    return (
        <div className="brands-container">
            {
                props.data.brandsModels.map((el, i) => {
                    return <BrandCard selected={props.selected} selectBrand={selectBrand} title={el.title} id={el.id} key={i} />
                })
            }
        </div>
    )
}

export default Brands;