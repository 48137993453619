import React from "react";
import "../../styles/Card.css"

const PreferedTimeCard = (props) => {
    return (
        <div className={"card" + ((props.el.id === props.selected.value?.id) ? " selected" : "")} onClick={() => {
            props.selectPreferedTime(props.el.id, props.el.title);
        }}>
            {props.el.title}
        </div>
    )
    
}

export default PreferedTimeCard;