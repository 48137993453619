import React from "react";
import '../styles/Quiz.css';
import QuizBody from "../components/Quiz/QuizBody"

class Quiz extends React.Component {

    render () {
        return (
            <div id="quiz">
                <QuizBody />
            </div>
        )
    }
}

export default Quiz;