import React from "react";

class PageNotFound extends React.Component {
    render () {
        return (
            <div>
                <h1>Error 404</h1>
                <h2>PageNotFound</h2>
            </div>
        )
    }
}

export default PageNotFound;