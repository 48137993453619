import React from "react";
import DefectCard from "./DefectCard";

import '../../styles/Defects.css';

const Defects = (props) => {

    // const removeSelected = (value) => {
    //     let updatedState = {'state': 'selectedDefects', 'value': props.selected.value.filter((el) => {
    //         return el !== value
    //     })}
    //     props.setSelected(updatedState);
    // }

    // const toggleSelected = (value) => {
    //     if (props.selected.value !== false && props.selected.value.some(el => el.id === value)) removeSelected(value)
    //     else addSelected(value)
    // }

    const addSelected = (defect) => {
        let updatedState = {
            'state': 'selectedDefects',
            'value': ((props.selected.value) !== false ? [...props.selected.value, defect] : [defect])
        }
        props.setSelected(updatedState);
    }

    const removeSelected = (defect) => {
        let updatedState = {
            'state': 'selectedDefects',
            'value': props.selected.value.filter(el => {
                return el.id !== defect.id
            }) 
        };
        props.setSelected(updatedState);
    }

    const toggleSelected = (defect) => {
        if (props.selected.value !== false && props.selected.value.some(el => el.id === defect.id)) removeSelected(defect);
        else addSelected(defect);
    }

    return (
        <div className="defects-container">
            {
                props.data.defects.map((el, i) => {
                    return <DefectCard key={ i } defect={ el } toggleSelected={toggleSelected} selected={ props.selected } />
                })
            }
        </div>
    )
    
}

export default Defects;