import React from "react";
import '../../styles/DefectCard.css'
import crackedScreen from "../../images/CrackedScreen.jpg";

const DefectCard = (props) => {
 
    return (
        <div className={
            "defect-card" 
            + ((props.selected.value !== false && props.selected.value.some(el => el.id === props.defect.id)) ? " selected" : "")
        } onClick={() => {
            props.toggleSelected({'id': props.defect.id, 'title': props.defect.title})
        }}>
            <div className="img-container">
                <img src={crackedScreen} alt="cracked screen" className="defect-img"></img>
            </div>
            <div className="title-container">
                <span>{props.defect.title}</span>
            </div>
        </div>
    )
}

export default DefectCard;