import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import "../../styles/QuizBody.css"

import Brands  from "./Brands";
import Models from "./Models";
import ConfirmButton from "./ConfirmButton";
import Info from "./Info";
import Defects from "./Defects"
import PreferedTime from "./PreferedTime";
import NamePhoneForm from "./NamePhoneForm";
import PriceOutput from "./PriceOutput";

const QuizBody = (props) => {
    // Component's states
    const [step, setStep] = useState(1);

    const [isFetching, setIsFetching] = useState(true);

    const [values, setValues] = useState({
        brand: false,
        model: false,
        selectedDefects: false,
        preferedTime: false,
        discount: 10
    });

    const [repairType, setRepairType] = useState(false);
    const [price, setPrice] = useState(false);

    const [customerInfo, setCustomerInfo] = useState({
        firstName: false,
        phoneNumber: false
    });
    
    const [clientAnswerId, setClientAnswerId] = useState(false);

    const [selected, setSelected] = useState(false);

    const [data, setData] = useState({
        brandsModels: [],
        repairTypes: [],
        defects: [],
    });
    
        // axios server requests

    const server_ip = ''

    const addClient = async (postData) => {
        let postClientRequest = await axios.post(`/api/client`, postData);
        setClientAnswerId(postClientRequest.data.client_answer_id);
    }

    const getPrice = async () => {
        if (clientAnswerId === false) return;
        const clientAnswerInfoRes = await axios.get(`/api/client_answer/${clientAnswerId}/info`)
        if (clientAnswerInfoRes.status === 204) {
            setRepairType("not found");
            setPrice("not found");
            return;
        }
        setRepairType(clientAnswerInfoRes.data.repair_type);
        setPrice(clientAnswerInfoRes.data.price);
    }

    const fetchData = async () => {
        try {
            const brandsModelsRes = await axios.get(`/api/brandsmodels`);
            const repairTypesRes = await axios.get(`/api/repairtypes`);
            const defectsRes = await axios.get(`/api/defects`);            
            let brandsModels = [];
            let repairTypes = [];
            let defects = [];
            Object.keys(brandsModelsRes.data).forEach((key) => {
                let el = brandsModelsRes.data[key];
                brandsModels.push(el);
            })
            Object.keys(repairTypesRes.data).forEach((key) => {
                let el = repairTypesRes.data[key];
                repairTypes.push(el);
            })
            Object.keys(defectsRes.data).forEach((key) => {
                let el = defectsRes.data[key];
                defects.push(el);
            })
            setData({brandsModels: brandsModels, repairTypes: repairTypes, defects: defects});
            setIsFetching(false);
        } catch (error) {
            alert(error)
        }
    }

        // Setting up starter data

    useEffect(() => {
        fetchData();
    }, [])

        // Helper functions

    const nextStep = () => {
        setValue(selected.state, selected.value);
        setSelected({'state': false, 'value': false});
        setStep(step + 1);
    }

// eslint-disable-next-line
    const prevStep = () => {
        setSelected({'state': false, 'value': false});
        setStep(step - 1);
    }

    const setValue = (toChange, value) => {
        setValues({
            ...values,
            [toChange]: value
        })
    }

    const selectContent = () => {
        switch(step) {
            case 1:
                return (
                    <motion.div
                    initial={{ opacity: 0, x: 0 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeInOut" }}
                    exit={{ opacity: 0, x: -20 }}
                    key={1}
                    className="quiz-body">

                        <Brands 
                            selected={ selected }
                            setSelected={ setSelected }
                            data={ data }
                            setValue={ setValue }
                            values={ values }
                        />
                        <ConfirmButton nextStep={ nextStep } values={values} step={step} hidden={selected}  /> 

                    </motion.div>
                )
            case 2:
                return (
                    <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeInOut" }}
                    exit={{ opacity: 0, x: -20 }}
                    key={2}
                    className="quiz-body">
                        <Models 
                            selected={ selected }
                            setSelected={ setSelected }
                            data={ data }
                            setValue={ setValue }
                            values={ values }
                        />
                        <ConfirmButton nextStep={ nextStep } values={values} step={step} hidden={selected} />
                    </motion.div>
                )
            case 3:
                return (
                    <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeInOut" }}
                    exit={{ opacity: 0, x: -20 }}
                    key={3}
                    className="quiz-body info-quiz-body">
                        <div className="component-and-btn-container">
                            <Defects
                            selected={ selected }
                            setSelected={ setSelected }
                            data={ data }
                            setValue={ setValue }
                            values={ values }
                            />
                            <ConfirmButton nextStep={ nextStep } values={values} step={step} hidden={selected} />
                        </div>
                        <Info values={ values } />
                    </motion.div>
                )
            case 4:
                return (
                    <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeInOut" }}
                    exit={{ opacity: 0, x: -20 }}
                    key={4}
                    className="quiz-body info-quiz-body">
                        <div className="component-and-btn-container">
                            <PreferedTime 
                            selected={ selected }
                            setSelected={ setSelected }
                            data={ data }
                            setValue={ setValue }
                            values={ values }
                            />
                            <ConfirmButton nextStep={ nextStep } values={ values } step={ step } hidden={ selected } />
                        </div>
                        <Info values={ values } />
                    </motion.div>
                )
            case 5:
                return (
                    <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeInOut" }}
                    exit={{ opacity: 0, x: -20 }}
                    key={5}
                    className="quiz-body info-quiz-body">
                        <NamePhoneForm
                        values={values}
                        addClient={addClient}
                        customerInfo={customerInfo}
                        setCustomerInfo={setCustomerInfo}
                        step={step}
                        setStep={setStep}
                        />
                        <Info values={ values } />
                    </motion.div>
                )
            case 6:
                return (
                    <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeInOut" }}
                    exit={{ opacity: 0, x: -20 }}
                    key={6}
                    className="quiz-body info-quiz-body">
                        <PriceOutput 
                        repairType={repairType}
                        price={price}
                        getPrice={getPrice}
                        />
                        <Info values={values}></Info>
                    </motion.div>
                )
            default:
                return null;
        }
    }

    const LoadingScreenIfFetching = () => {
        if (isFetching) {
            return (
                <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.2, ease: "easeInOut" }}
                exit={{ opacity: 0 }}
                key={0}
                className="loading-screen">
                    Loading...
                </motion.h1>
            )
        }
        else  {
            return selectContent()
        }
    }

    return (
        <div className="quiz">
            <AnimatePresence exitBeforeEnter initial={false}>
                {LoadingScreenIfFetching()}
            </AnimatePresence>
        </div>
    )
}

export default QuizBody;