import React from "react";
import "../../styles/BrandCard.css";

const BrandCard = (props) => {
    return(
        <div
        className={"brand-card" + ((props.id === props.selected?.value?.id ) ? " selected" : "")} onClick={() => {
            props.selectBrand({'id': props.id, 'title': props.title});
        }}>
            {props.title}
        </div>
    )
}

export default BrandCard;