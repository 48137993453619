import React from "react";
import "../../styles/ConfirmButton.css";
import { motion } from "framer-motion";

const ConfirmButton = (props) => {
    

    return (
        <div className="confirm-button">
            {!props.hidden
                ? ""
                : <motion.button 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                duration: 0.2,
                delay: 0.1,
                ease: "easeInOut"
                }}
                className="confirm-btn" onClick={() => {
                    props.nextStep()
                }}>
                Продолжить 
                </motion.button>
            }
            
        </div>
        
    )
}

export default ConfirmButton;